import { environment } from './../../../environments/environment';

const BASE_EC_GATEWAY_API_COMPOSE = `${environment.api.ecGatewayNginx}api/compose/`;

// GATEWAY NGINX
const EC_GATEWAY_NGINX_WS = `${environment.api.ecGatewayNginx}`;

export const USERS_EFFY_PASSWORDLESS_WS = `${environment.api.user}passwordless_users`;

export const USER_EFFY_INFOS_WS = `${environment.api.user}users`;

export const USER_EFFY_LOGIN_WS = `${environment.api.user}users/login`;

export const USERS_SUBSCRIBE_NEWSLETTER = `${BASE_EC_GATEWAY_API_COMPOSE}v1/email-subscriptions`;

export const USER_ENRICHMENT_BASE_URL_WS = environment.api.enrichment;

export const WS_REQUIRING_API_KEY = [USERS_EFFY_PASSWORDLESS_WS, USERS_SUBSCRIBE_NEWSLETTER, USER_EFFY_LOGIN_WS, USER_EFFY_INFOS_WS];

export const WS_REQUIRING_SESSION_TOKEN = [EC_GATEWAY_NGINX_WS, USER_EFFY_INFOS_WS, USER_ENRICHMENT_BASE_URL_WS];
