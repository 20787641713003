import { createAction } from '@ngrx/store';

export enum ConfirmationAction {
  Init = '[Confirmation] Init',
  GoToEligible = '[Confirmation] Go To Eligible',
  GoToIneligible = '[Confirmation] Go To Ineligible',
}

export const CONFIRMATION_INIT_ACTION = createAction(ConfirmationAction.Init);
export const CONFIRMATION_GO_TO_ELIGIBLE_ACTION = createAction(ConfirmationAction.GoToEligible);
export const CONFIRMATION_GO_TO_INELIGIBLE_ACTION = createAction(ConfirmationAction.GoToIneligible);
