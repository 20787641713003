import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CookiesToken } from '../../configs';
import {
  AccountInfosResponseModel,
  CreateAccountPasswordlessResponseModel,
  CreateUserEffyPasswordlessModel,
  LoginAccountResponseModel,
  LoginUserEffyModel,
  UserSubscribeNewsletterRequestModel,
  UserSubscribeNewsletterSource,
} from '../../models';
import { CookieManagerService } from '../cookie-manager/cookie-manager.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly httpClient: HttpClient, private readonly cookieManagerService: CookieManagerService) {}

  callPasswordlessAPI(
    body: CreateUserEffyPasswordlessModel,
    passwordLessUrlApi: string
  ): Observable<CreateAccountPasswordlessResponseModel> {
    return this.httpClient.post<CreateAccountPasswordlessResponseModel>(passwordLessUrlApi, body);
  }

  login(body: LoginUserEffyModel, loginUrlApi: string): Observable<LoginAccountResponseModel> {
    return this.httpClient.post<LoginAccountResponseModel>(loginUrlApi, body);
  }

  register(body: LoginUserEffyModel, usersUrlApi: string): Observable<AccountInfosResponseModel> {
    return this.httpClient.post<AccountInfosResponseModel>(usersUrlApi, body);
  }

  saveCookieUser(sessionToken: string, refreshToken: string): void {
    this.cookieManagerService.setCookie(CookiesToken.sessionToken, sessionToken);
    this.cookieManagerService.setCookie(CookiesToken.refreshToken, refreshToken);
  }

  getAccountInfo(userInfoApiUrl: string): Observable<AccountInfosResponseModel> {
    return this.httpClient.get<AccountInfosResponseModel>(userInfoApiUrl);
  }

  subscribeToNewsletter(email: string, source: UserSubscribeNewsletterSource, newsLetterApiUrl: string) {
    const body: UserSubscribeNewsletterRequestModel = {
      email,
      optInNewsletter: true,
      source,
    };

    return this.httpClient.post(newsLetterApiUrl, body);
  }
}
